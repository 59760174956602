<template>
    <!-- <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"> -->
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(33, 150, 243, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
                <div class="flex justify-content-center h-full w-full m-0  " style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="grid flex-column align-items-center">
                        <span class="text-blue-500 font-bold text-3xl">***</span>
                        <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Data Not Found</h1>
                        <span class="text-gray-600">Requested Data is not available.</span>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>