<template>
    <div>
      <Navigation></Navigation>
      <megamenu @onFilter="handleChildFunctionCalled"></megamenu>
      <brand></brand>
      <pphfooter></pphfooter>
    </div>
  </template>
  
  <script>
  import Navigation from "../components/pphmenu.vue";
  import megamenu from "../components/pphmegamenu.vue";
  import brand from "../components/pphbrand.vue"
  
  import pphfooter from "../components/pph_footer.vue";
  
  export default {
    name: "pphbrandds",
    data() {
      return {
        filters:{}
      };
    },
    components: {
      Navigation,
      megamenu,
      brand,
      pphfooter,
    },
    mounted() {},
  methods: {
    async handleChildFunctionCalled(data) 
    {
      await this.GoToListWithFilter(data.brand, data.product_type, data.type, data.search);
    },
    async GoToListWithFilter(brand="", product_type = "", type = "", search = "") 
    {
     
      if (brand) {
        if (this.filters.brand) {
          this.filters.brand.push(brand);
        } else {
          this.filters.brand = [];
          this.filters.brand.push(brand);
        }
      }
      if (type) 
      {

        if (this.filters.type) 
        { 
          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
          
        } 
        else 
        {
          this.filters.type = [];

          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
        }
      }
      if (product_type) {
        if (this.filters.product_type) {
          this.filters.product_type.push(product_type);
        } else {
          this.filters.product_type = [];
          this.filters.product_type.push(product_type);
        }
      }
      if (search) {
        this.filters.search = search;
      }
      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });
    
    },
  },
  };
  </script>
  