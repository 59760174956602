<template>
  <div class="container mt-5 mb-5">
    <Loader v-if="loading" />
    
    <div class="row">
      <div class="row">
        <div class="col-md-4 mb-5">
          <div class="input-group">
            <input
              type="search"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              @input="filterJsonByNameSubstring"
              aria-describedby="search-addon"
              v-model="brand_search"
            />
            <button
              type="button"
              @click="filterJsonByNameSubstring"
              class="btn btn-outline-primary rounded-pill"
              data-mdb-ripple-init
            >
              search
            </button>
          </div>
        </div>
      </div>
      <DataNotFoundVue v-if="products.length<1"></DataNotFoundVue>
      <div
        class="col-sm-2 col-md-2"
        v-for="(data, index) in products"
        :key="index"
      >
        <div class="card shadow" @click="goto(data)">
          <img :src="data.url" class="card-img-top" alt="" />
          <div class="card-body mt-1" style="background-color: #fff">
            <p
              class="card-text text-center"
              style="
                color: #fff;
                background: #0f75bc;
                font-weight: bold;
                padding: 4px 3px 4px 3px;
                border-radius: 10px;
              "
            >
              {{ data.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apis from "@/apis";
import Loader from "../components/Loader.vue";
import DataNotFoundVue from "./DataNotFound.vue";
export default {
  data() {
    return {
      
      filters: {
        brand: []
      },
      loading: false,
      brand_search: "",
      products: [],
      products_original: [],
      images: [],
      galleriaResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "960px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  components: {
    //selectedCity, cities, isVisible, products, toggleElement
    Loader,DataNotFoundVue
  },
  methods: {
    goto(data) {
      this.filters.brand.push(data.name);
      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });
    },
    filterJsonByNameSubstring() {
      this.loading = true;
      this.products = this.products_original.filter((item) =>
        item.name.toLowerCase().includes(this.brand_search)
      );
      this.loading = false;
    },
    GetBrand() {
      var data = { count: false, name: this.brand_search };
      this.loading = true;
      var promise = apis.GetBrandsList(data);
      promise.then((response) => {
        this.loading = false;
        //console.log("GetBrand", response.data.data);
        this.products = response.data.data;
        this.products_original = response.data.data;
      });
    },
  },
  mounted() {
    this.GetBrand();
  },
};
</script>
<style scoped>
.card {
  
  padding: 1rem !important;
}
.shadow {
 border-radius: 2px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  /* .card {
    height: 211px !important;
  } */
}
</style>
